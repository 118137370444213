@import '../libs/vars';
@import '../libs/functions';
@import '../libs/mixins';

///
/// Identity by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		@include vendor('appearance', 'none');
		@include vendor('transition', (
			'background-color #{_duration(transition)} ease-in-out',
			'border-color #{_duration(transition)} ease-in-out',
			'color #{_duration(transition)} ease-in-out'
		));
		display: inline-block;
		height: _size(element-height);
		line-height: _size(element-height);
		padding: 0 1.5em;
		background-color: transparent;
		border-radius: _size(border-radius);
		border: solid 1px _palette(border);
		color: _palette(fg) !important;
		cursor: pointer;
		text-align: center;
		text-decoration: none;
		white-space: nowrap;

		&:hover {
			border-color: _palette(highlight);
			color: _palette(highlight) !important;
		}

		&.icon {
			padding-left: 1.35em;

			&:before {
				margin-right: 0.5em;
			}
		}

		&.fit {
			display: block;
			width: 100%;
			margin: 0 0 (_size(element-margin) * 0.5) 0;
		}

		&.small {
			font-size: 0.8em;
		}

		&.big {
			font-size: 1.35em;
		}

		&.disabled,
		&:disabled {
			@include vendor('pointer-events', 'none');
			opacity: 0.5;
		}
	}