// Misc.
	$misc: (
		z-index-base:		10000
	);

// Duration.
	$duration: (
		transition:			0.2s,
		bg:					1.75s,
		main:				1s
	);

// Size.
	$size: (
		border-radius:		4px,
		border-width:		1px,
		element-height:		2.75em,
		element-margin:		1.5em,
		letter-spacing:		0.2em
	);

// Font.
	$font: (
		family:				('Source Sans Pro', Helvetica, sans-serif),
		family-fixed:		('Courier New', monospace),
		weight:				300
	);

// Palette.
	$palette: (
		bg:					#ffffff,
		bg-alt:				#e1dfe8,
		fg:					#414f57,
		fg-bold:			#313f47,
		fg-light:			#616f77,
		border:				#c8cccf,
		accent1:			#ffa596,
		accent2:			#00e4ff,
		highlight:			#ff7496
	);